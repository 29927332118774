.timeline {
  width: 100%;
  height: 20vh;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  background: linear-gradient(#1d3649, #152935);

  /* Form biar ketengah */
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-header {
  width: 100%;
  height: 4.25vh;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  padding-bottom: 0.5vh;
  border-top: 1px solid #325c80;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeline-header h2 {
  font-size: 2.25vh;
  padding-left: 1vh;
  font-weight: bold;
  color: #e0e0e0;
}

.section-header {
  width: 100%;
  padding-left: 1vh;
  padding-right: 1vh;
  font-size: 1.25vh;
  line-height: 1.5vh;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #aeaeae;
}

.timeline-header-desc {
  display: flex;
  align-items: center;
}

.section-header > span {
  margin-right: 5px;
}

.linkUrl a {
  font-size: 2vh;
  margin-right: 10px;
  text-decoration: none;
  color: tomato;
  cursor: pointer;
}

.linkUrl a:hover {
  color: rgb(245, 59, 26);
}

.timeline-svg {
  height: 80.5%;
  width: 100%;
  /* padding: 0.5% 1% 0 1%; */
  /* background: linear-gradient(#152935, #010205); */
}

.timeline form {
  height: 40px;
  width: 300px;
}

.timeline input {
  font-size: 16px;
  height: 26px;
  width: 200px;
  margin-right: 4px;
}
.timeline button {
  height: 26px;
  width: 40px;
  font-size: 16px;
}

.timeline button:hover {
  cursor: pointer;
  background-color: #152935;
  color: white;
}
