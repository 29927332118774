.home {
  background: linear-gradient(#1d3649, #152935);
  width: 100vw;
  height: 100vh;
  position: relative;
}

.main-content {
  display: flex;
  width: 100%;
  height: 73vh;
}

.right-content {
  width: 33.3%;
  height: 100%;
  position: relative;
  background: linear-gradient(#1d3649, #152935);
  border: 1px solid #325c80;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 12px;
}

/* Mobile */
@media (max-width: 768px) {
  .home {
    width: 100%;
  }

  .main-content {
    flex-wrap: wrap;
    margin-top: 2px;
  }

  .right-content {
    width: 100%;
  }
}
